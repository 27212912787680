import styled from '../../style';

export const Header = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const PositionWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

export const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 4,
});

export const ButtonSocialMedia = styled.div({
  backgroundColor: '#EDEDED',
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px 8px',
  marginRight: 8,
  width: 30,
  height: 30,
  cursor: 'pointer',
});
