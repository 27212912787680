import React from 'react';

const Medium = ({ className = '' }) => {
  return (
    <svg className={className} width="13" height="10" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.54000413 2.04012507c.0161257-.1537259-.04568947-.3074518-.16394459-.41167275L.16394459.21104742V0h3.76803804l2.91337606 6.19332986L9.40665702 0H13v.21104742l-1.0374199.96404377c-.0886913.0651381-.1343808.17457009-.1155675.28139656v7.0870245c-.0188133.10682647.0268762.21625846.1155675.28139656l1.0132314.96404377V10H7.87740335v-.21104742l1.05085797-.98749349c.10212942-.0990099.10212942-.13027618.10212942-.28139656V2.79312142L6.10895183 9.98176133h-.3950796L2.31672524 2.79312142v4.81761334c-.02956378.20323085.04031425.4064617.18813314.55237102l1.36530908 1.60500261v.21104742H0v-.2084419l1.36530908-1.60760813c.14513128-.14590932.21232168-.3517457.17469505-.55237102V2.04012507z"
        fill="#190D33"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default Medium;
