import React from 'react';

const Linkedin = ({ className = '' }) => {
  return (
    <svg className={className} width="12" height="10" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.1269561 9.9714286H.9385805V3.29508929h2.1883756V9.9714286zM2.0315905 2.384375c-.6996206 0-1.2673262-.54910714-1.2673262-1.21205357 0-.66294643.5677056-1.20089286 1.2673262-1.20089286S3.2989167.509375 3.2989167 1.17232143 2.7312111 2.384375 2.0315905 2.384375zm9.2858737 7.5870536H9.1337998v-3.25c0-.7745536-.0164893-1.7678572-1.1377668-1.7678572-1.1377669 0-1.3120831.8415179-1.3120831 1.7120536v3.3058036h-2.18602V3.29508929h2.0988618v.91071431h.0306232c.2920974-.5245536 1.0058518-1.07812503 2.0705943-1.07812503 2.2142875 0 2.6218106 1.38169643 2.6218106 3.17633933v3.6674107h-.0023556z"
        fill="#190D33"
      />
    </svg>
  );
};
export default Linkedin;
