import React from 'react';

const Facebook = ({ className = '' }) => {
  return (
    <svg className={className} width="5" height="10" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.08712121 1.66015625H5V.0703125C4.84280303.04882812 4.30113636 0 3.67045455 0 2.35416667 0 1.45265152.828125 1.45265152 2.34960938V3.75H0v1.77734375h1.45265152V10h1.78030303V5.52734375h1.39393939L4.84848485 3.75h-1.6155303V2.52539062c0-.51367187.13825757-.86523437.85416666-.86523437z"
        fill="#190D33"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default Facebook;
